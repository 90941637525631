import axios from 'axios';
import {
  updateLearner as proxyLearner,
  updateLearners as proxyLearners,
  makeObjectUser,
} from '../utils';

export const CLEAN_USER = 'CLEAN_USER';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_USER = 'SET_USER';
export const UPDATE_INFO = 'UPDATE_INFO';
export const UPDATE_ACTUAL_LEARNER = 'UPDATE_ACTUAL_LEARNER';
export const UPDATE_LEARNERS = 'UPDATE_LEARNERS';
export const UPDATE_LEARNER = 'UPDATE_LEARNER';
export const LOGOUT = 'LOGOUT';
export const GET_LEARNER_SESSIONS_SUCCESS = 'GET_LEARNER_SESSIONS_SUCCESS';
export const LEARNER_SESSIONS_FAILURE = 'LEARNER_SESSIONS_FAILURE';
export const CLEAN_SESSIONS = 'CLEAN_SESSIONS';
export const UPDATE_LEARNER_BY_ID = 'UPDATE_LEARNER_BY_ID';
export const UPDATE_LEARNER_BY_ID_FAILURE = 'UPDATE_LEARNER_BY_ID_FAILURE';
export const GET_LEARNER_CREDIT = 'GET_LEARNER_CREDIT';
export const GET_LEARNER_CREDIT_FAILURE = 'GET_LEARNER_CREDIT_FAILURE';
export const SEND_TUTOR_SESSIONS_DURATION = 'SEND_TUTOR_SESSIONS_DURATION';
export const GET_RECOMMENDED_TUTORS_SUCCESS = 'GET_RECOMMENDED_TUTORS_SUCCESS';
export const GET_SESSION_TUTORS_SUCCESS = 'GET_SESSION_TUTORS_SUCCESS';
export const GET_TUTOR_SESSIONS_DURATION_FAILURE =
  'GET_TUTOR_SESSIONS_DURATION_FAILURE';
export const CALENDLY_RETRY_LEARNERS = 'CALENDLY_RETRY_LEARNERS';

const SEND_GET_RECOMMENDED_TUTORS = 'SEND_GET_RECOMMENDED_TUTORS';
const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/profile/api/v1/`;
const HOST_PAYMENT = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/payment/api/v1/`;
const HOST_TUTORING = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}/tutoring/api/v1/`;

export const cleanUser = () => ({
  type: CLEAN_USER,
});

export const cleanSessions = () => ({
  type: CLEAN_SESSIONS,
});

export const logOut = () => ({
  type: LOGOUT,
});

export const sendTutorSessionsDuration = () => ({
  type: SEND_TUTOR_SESSIONS_DURATION,
});

export const setDevices = (devices) => ({
  type: SET_DEVICES,
  devices,
});

export const setUser = (user) => ({
  type: SET_USER,
  user,
});

const updateInfo = (info) => ({
  type: UPDATE_INFO,
  info,
});

export const updateActualLearner = (actualLearner) => ({
  type: UPDATE_ACTUAL_LEARNER,
  actualLearner,
});

export const updateLearner = (learner) => ({
  type: UPDATE_LEARNER,
  learner: proxyLearner(learner),
});

export const updateLearners = (learners) => ({
  type: UPDATE_LEARNERS,
  learners: proxyLearners(learners),
});

const learnerSessionsSuccess = (sessions) => ({
  type: GET_LEARNER_SESSIONS_SUCCESS,
  sessions,
});

const learnerSessionsFailure = () => ({
  type: LEARNER_SESSIONS_FAILURE,
});

const updateLearnerById = (learnerId, learnerData) => ({
  type: UPDATE_LEARNER_BY_ID,
  learnerId,
  learnerData,
});

const updateLearnerByIdFailure = (err) => ({
  type: UPDATE_LEARNER_BY_ID_FAILURE,
  err,
});

const getCreditSend = () => ({
  type: GET_LEARNER_CREDIT,
});

const getCreditFailure = () => ({
  type: GET_LEARNER_CREDIT_FAILURE,
});

export const sendGetRecommendedTutors = () => ({
  type: SEND_GET_RECOMMENDED_TUTORS,
});

export const getRecommendedTutorsSuccess = (recommendedTutors) => ({
  type: GET_RECOMMENDED_TUTORS_SUCCESS,
  recommendedTutors,
});

export const getTutorSessionsDurationFailure = () => ({
  type: GET_TUTOR_SESSIONS_DURATION_FAILURE,
});

export const getLearnerCredit = () => {
  return (dispatch, getState) => {
    dispatch(getCreditSend());
    const { actualLearner } = getState().user;
    axios
      .get(
        `${HOST_PAYMENT}discount/credit/learner/${actualLearner.learnerId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )
      .then((res) => {
        const newLearner = {
          ...actualLearner,
          credit: res.data.discount,
        };
        dispatch(updateLearner(newLearner));
      })
      .catch(() => {
        dispatch(getCreditFailure());
        const newLearner = {
          ...actualLearner,
          credit: 0,
        };
        dispatch(updateLearner(newLearner));
      });
  };
};

export const updateUser = (userOptions) => {
  const userID = localStorage.getItem('userID');
  const token = localStorage.getItem('token');
  return (dispatch) => {
    const user = { onboardingDone: true };
    localStorage.setItem('currentUser', JSON.stringify(user));

    axios
      .patch(`${HOST_PROFILE}user/${userID}`, userOptions, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(setUser(makeObjectUser(res.data)));
        const data = makeObjectUser(res.data);
        dispatch(updateInfo(data.info));
        dispatch(updateLearners(data.learners));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('getUser Error', err);
      });
  };
};

export const getUser = (credit?) => {
  return (dispatch) => {
    const userID = localStorage.getItem('userID');
    const token = localStorage.getItem('token');
    axios
      .get(`${HOST_PROFILE}user/${userID}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(setUser(makeObjectUser(res.data)));
        if (credit) {
          dispatch(getLearnerCredit());
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('getUser Error', err);
      });
  };
};

export const logout = () => {
  localStorage.clear();
  return (dispatch) => {
    dispatch(logOut());
  };
};

export const getLearnerSessions = () => {
  return (dispatch, getState) => {
    const token = localStorage.getItem('token');
    const { actualLearner } = getState().user;
    axios
      .get(
        `${HOST_TUTORING}package/sessions?learnerId=${actualLearner.learnerId}&packageType=screener`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.data.content?.fixed) {
          dispatch(learnerSessionsSuccess(res.data.content.fixed));
        }
      })
      .catch((err) => {
        dispatch(learnerSessionsFailure());
        // eslint-disable-next-line no-console
        console.error('getUser Error', err);
      });
  };
};

export const getLearnerSessionsById = (learnerId, completed?) => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    axios
      .get(
        `${HOST_TUTORING}package/sessions?learnerId=${learnerId}&size=40&packageType=tutoring${
          completed && '&excludeCompletedSessions=true'
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.data.content?.dynamic_online) {
          dispatch(
            updateLearnerById(learnerId, res.data.content.dynamic_online)
          );
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('getUser Error', err);
        dispatch(updateLearnerByIdFailure(err.message));
      });
  };
};

export interface ISession {
  endDate: string;
  eventType: string;
  externalMeetingId: string | null;
  id: string;
  inviteeId: string | null;
  learnerId: string;
  sessionDetails: string | null;
  sessionName: string;
  sessionStatus: { id: number; description: string; name: string };
  startDate: string;
  tutorId: string;
  userId: string;
  videoConferenceLink: string | null;
  tutor?: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export const getRecommendedTutors = () => (dispatch, getState) => {
  dispatch(sendGetRecommendedTutors());
  const token = localStorage.getItem('token');
  const { learnerId } = getState().user.actualLearner;
  axios
    .get(`${HOST_PROFILE}recommendation/learner/${learnerId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch(getRecommendedTutorsSuccess(res.data));
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.error('getrecommended Tutor Error');
    });
};

export const getTutorSessionsDuration = (tutorEmail) => (dispatch) => {
  dispatch(sendTutorSessionsDuration());
  const token = localStorage.getItem('token');
  axios
    .get(`${HOST_TUTORING}tutor/calendarEvents?tutorEmail=${tutorEmail}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch({
        type: GET_SESSION_TUTORS_SUCCESS,
        availableSessions: res.data,
      });
    })
    .catch(() => {
      dispatch(getTutorSessionsDurationFailure());
    });
};
