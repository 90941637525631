/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { updateLearner, updateUser } from '../../commonActions';

export const GET_SCREENER_BUNDLE_SEND = 'GET_SCREENER_BUNDLE_SEND';
export const GET_SCREENER_BUNDLE_SUCCESS = 'GET_SCREENER_BUNDLE_SUCCESS';
export const GET_SCREENER_BUNDLE_FAILURE = 'GET_SCREENER_BUNDLE_FAILURE';
export const GET_BUNDLES_SEND = 'GET_BUNDLES_SEND';
export const GET_BUNDLES_SUCCESS = 'GET_BUNDLES_SUCCESS';
export const GET_DISCOUNT_RESET = 'GET_DISCOUNT_RESET';
export const GET_BUNDLES_FAILURE = 'GET_BUNDLES_FAILURE';
export const BUNDLES_PURCHASED = 'BUNDLES_PURCHASED';
export const GET_DISCOUNT_SEND = 'GET_DISCOUNT_SEND';
export const GET_DISCOUNT_SUCCESS = 'GET_DISCOUNT_SUCCESS';
export const GET_DISCOUNT_FAILURE = 'GET_DISCOUNT_FAILURE';
export const GET_BT_TOKEN = 'GET_BT_TOKEN';
export const GET_BT_TOKEN_SUCCESS = 'GET_BT_TOKEN_SUCCESS';
export const GET_BT_TOKEN_FAILURE = 'GET_BT_TOKEN_FAILURE';
export const SEND_PURCHASE = 'SEND_PURCHASE';
export const SEND_PURCHASE_FAILURE = 'SEND_PURCHASE_FAILURE';
export const RESET_PAYMENT_FAILURE_MESSAGE = 'RESET_PAYMENT_FAILURE_MESSAGE';
export const RESET_PAYMENT_OPTIONS = 'RESET_PAYMENT_OPTIONS';
export const RESET_BT_TOKEN = 'RESET_BT_TOKEN';

interface IProductObj {
  productId: string;
  discountCode?: string;
  quantity?: number;
}
interface IPurchaseObj {
  nonce: string;
  products: IProductObj[];
  discountCode?: string;
  quantity?: number;
}

const HOST_PROFILE = `${process.env.REACT_APP_PROFILE_SERVICE_HOST}`;

const getDiscountSend = () => ({
  type: GET_DISCOUNT_SEND,
});

const getDiscountReset = () => ({
  type: GET_DISCOUNT_RESET,
});

const getDiscountSuccess = (discount) => ({
  type: GET_DISCOUNT_SUCCESS,
  discount,
});

const getDiscountFailure = () => ({
  type: GET_DISCOUNT_FAILURE,
});

const getScreenerBundleSend = () => ({
  type: GET_SCREENER_BUNDLE_SEND,
});

const getScreenerBundleSuccess = (screener) => ({
  type: GET_SCREENER_BUNDLE_SUCCESS,
  screener,
});

const getScreenerBundleFailure = () => ({
  type: GET_SCREENER_BUNDLE_FAILURE,
});

const getBundlesSend = () => ({
  type: GET_BUNDLES_SEND,
});

const getBundlesSuccess = (bundles) => ({
  type: GET_BUNDLES_SUCCESS,
  bundles,
});

const getBundlesFailure = () => ({
  type: GET_BUNDLES_FAILURE,
});

const bundlesPurchased = () => ({
  type: BUNDLES_PURCHASED,
});

const getBraintreeTokenSend = () => ({
  type: GET_BT_TOKEN,
});

const getBraintreeTokenSuccess = (btToken) => ({
  type: GET_BT_TOKEN_SUCCESS,
  btToken,
});

const getBraintreeTokenFailure = () => ({
  type: GET_BT_TOKEN_FAILURE,
});

const getPurchase = () => ({
  type: SEND_PURCHASE,
});

const getPurchaseFailure = () => ({
  type: SEND_PURCHASE_FAILURE,
});

const resetPaymentFailureMessage = () => ({
  type: RESET_PAYMENT_FAILURE_MESSAGE,
});

const handleResetPaymentOptions = () => ({
  type: RESET_PAYMENT_OPTIONS,
});

export const resetBraintreeToken = () => ({
  type: RESET_BT_TOKEN,
});

export const getInitialPlan = () => {
  return (dispatch) => {
    dispatch(getScreenerBundleSend());
    axios
      .get(`${HOST_PROFILE}/payment/api/v1/product/screener`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        dispatch(getScreenerBundleSuccess(res.data.bundles));
      })
      .catch(() => {
        dispatch(getScreenerBundleFailure());
      });
  };
};

export const getBundles = () => {
  return (dispatch) => {
    dispatch(getBundlesSend());
    axios
      .get(`${HOST_PROFILE}/payment/api/v1/product/bundle`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        const slim = res.data.bundles.filter(
          (b) => b.name === 'Learner Sessions'
        );
        dispatch(getBundlesSuccess(slim));
      })
      .catch(() => {
        dispatch(getBundlesFailure());
      });
  };
};

export const getDiscount = (code) => {
  return (dispatch, getState) => {
    const { actualLearner } = getState().user;
    dispatch(getDiscountSend());
    axios
      .get(
        `${HOST_PROFILE}/payment/api/v1/discount/code/${code}/learner/${actualLearner.learnerId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )
      .then((res) => {
        dispatch(getDiscountSuccess(res.data));
      })
      .catch(() => {
        dispatch(getDiscountFailure());
      });
  };
};

export const getBraintreeToken = () => {
  return (dispatch) => {
    dispatch(getBraintreeTokenSend());
    axios
      .get(
        `${HOST_PROFILE}/payment/api/v1/gateway/${localStorage.getItem(
          'userID'
        )}/token`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )
      .then((res) => {
        if (res.status === 200)
          dispatch(getBraintreeTokenSuccess(res.data.token));
      })
      .catch(() => {
        dispatch(getBraintreeTokenFailure());
      });
  };
};

export const sendPurchase = (initialPlan, purchaseInfo) => {
  const purchaseObject = {
    nonce: purchaseInfo.nonce,
    products: [],
  } as IPurchaseObj;

  const productObject = {
    productId: purchaseInfo.productId,
  } as IProductObj;

  if (purchaseInfo.discountCode)
    productObject.discountCode = purchaseInfo.discountCode;
  if (purchaseInfo.quantity) productObject.quantity = purchaseInfo.quantity;

  purchaseObject.products.push(productObject);

  return (dispatch, getState) => {
    dispatch(getPurchase());
    axios
      .post(
        `${HOST_PROFILE}/payment/api/v1/gateway/${localStorage.getItem(
          'userID'
        )}/learner/${purchaseInfo.learnerId}/payment`,
        purchaseObject,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )
      .then(() => {
        const { actualLearner, info } = getState().user;
        if (initialPlan) {
          const newLearner = {
            ...actualLearner,
            initialPlanPayed: true,
            nextStep: 'learnerScreenerStatus',
          };
          dispatch(updateLearner(newLearner));
        } else {
          if (
            !info.enrollmentAgreementAccepted &&
            purchaseInfo.enrollmentStatus
          ) {
            dispatch(updateUser({ enrollmentAgreementAccepted: true }));
          }
          dispatch(bundlesPurchased());
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        dispatch(getPurchaseFailure());
      });
  };
};

export const resetDiscount = () => {
  return (dispatch) => {
    dispatch(getDiscountReset());
  };
};

export const resetPaymentFailure = () => {
  return (dispatch) => {
    dispatch(resetPaymentFailureMessage());
  };
};

export const resetPaymentOptions = () => {
  return (dispatch) => {
    dispatch(handleResetPaymentOptions());
  };
};
